'use strict'

var Buffer = require('safe-buffer').Buffer
var max = 65536
var cache = {}

function generateBuffer(i) {
	var buffer = Buffer.allocUnsafe(2)
	buffer.writeUInt8(i >> 8, 0)
	buffer.writeUInt8(i & 0x00FF, 0 + 1)

	return buffer
}

function generateCache() {
	for (var i = 0; i < max; i++) {
		cache[i] = generateBuffer(i)
	}
}

/**
 * calcVariableByteIntLength - calculate the variable byte integer
 * length field
 *
 * @api private
 */
function calcVariableByteIntLength(length) {
	if (length >= 0 && length < 128) return 1
	else if (length >= 128 && length < 16384) return 2
	else if (length >= 16384 && length < 2097152) return 3
	else if (length >= 2097152 && length < 268435456) return 4
	else return 0
}

function genBufVariableByteInt(num) {
	var digit = 0
	var pos = 0
	var length = calcVariableByteIntLength(num)
	var buffer = Buffer.allocUnsafe(length)

	do {
		digit = num % 128 | 0
		num = num / 128 | 0
		if (num > 0) digit = digit | 0x80

		buffer.writeUInt8(digit, pos++)
	} while (num > 0)

	return {
		data: buffer,
		length: length
	}
}

function generate4ByteBuffer(num) {
	var buffer = Buffer.allocUnsafe(4)
	buffer.writeUInt32BE(num, 0)
	return buffer
}

module.exports = {
	cache: cache,
	generateCache: generateCache,
	generateNumber: generateBuffer,
	genBufVariableByteInt: genBufVariableByteInt,
	generate4ByteBuffer: generate4ByteBuffer
}
