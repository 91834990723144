'use strict'

var Buffer = require('safe-buffer').Buffer
var writeToStream = require('./writeToStream')
var EE = require('events').EventEmitter
var inherits = require('inherits')

function generate(packet, opts) {
	var stream = new Accumulator()
	writeToStream(packet, stream, opts)
	return stream.concat()
}

function Accumulator() {
	this._array = new Array(20)
	this._i = 0
}

inherits(Accumulator, EE)

Accumulator.prototype.write = function (chunk) {
	this._array[this._i++] = chunk
	return true
}

Accumulator.prototype.concat = function () {
	var length = 0
	var lengths = new Array(this._array.length)
	var list = this._array
	var pos = 0
	var i
	var result

	for (i = 0; i < list.length && list[i] !== undefined; i++) {
		if (typeof list[i] !== 'string') lengths[i] = list[i].length
		else lengths[i] = Buffer.byteLength(list[i])

		length += lengths[i]
	}

	result = Buffer.allocUnsafe(length)

	for (i = 0; i < list.length && list[i] !== undefined; i++) {
		if (typeof list[i] !== 'string') {
			list[i].copy(result, pos)
			pos += lengths[i]
		} else {
			result.write(list[i], pos)
			pos += lengths[i]
		}
	}

	return result
}

module.exports = generate
