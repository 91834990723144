'use strict'

var websocket = require('../websocket-stream')
var urlModule = require('url')
var WSS_OPTIONS = [
	'rejectUnauthorized',
	'ca',
	'cert',
	'key',
	'pfx',
	'passphrase'
]
var IS_BROWSER = process.title === 'browser'

function buildUrl(opts, client) {
	var url = opts.protocol + '://' + opts.hostname + ':' + opts.port + opts.path
	if (typeof (opts.transformWsUrl) === 'function') {
		url = opts.transformWsUrl(url, opts, client)
	}
	return url
}

function setDefaultOpts(opts) {
	if (!opts.hostname) {
		opts.hostname = 'localhost'
	}
	if (!opts.port) {
		if (opts.protocol === 'wss') {
			opts.port = 443
		} else {
			opts.port = 80
		}
	}
	if (!opts.path) {
		opts.path = '/'
	}

	if (!opts.wsOptions) {
		opts.wsOptions = {}
	}
	if (!IS_BROWSER && opts.protocol === 'wss') {
		// Add cert/key/ca etc options
		WSS_OPTIONS.forEach(function (prop) {
			if (opts.hasOwnProperty(prop) && !opts.wsOptions.hasOwnProperty(prop)) {
				opts.wsOptions[prop] = opts[prop]
			}
		})
	}
}

function createWebSocket(client, opts) {
	var websocketSubProtocol =
		(opts.protocolId === 'MQIsdp') && (opts.protocolVersion === 3)
			? 'mqttv3.1'
			: 'mqtt'

	setDefaultOpts(opts)
	var url = buildUrl(opts, client)
	return websocket(url, [websocketSubProtocol], opts.wsOptions)
}

function buildBuilder(client, opts) {
	return createWebSocket(client, opts)
}

function buildBuilderBrowser(client, opts) {
	if (!opts.hostname) {
		opts.hostname = opts.host
	}

	if (!opts.hostname) {
		// Throwing an error in a Web Worker if no `hostname` is given, because we
		// can not determine the `hostname` automatically.  If connecting to
		// localhost, please supply the `hostname` as an argument.
		if (typeof (document) === 'undefined') {
			throw new Error('Could not determine host. Specify host manually.')
		}
		var parsed = urlModule.parse(document.URL)
		opts.hostname = parsed.hostname

		if (!opts.port) {
			opts.port = parsed.port
		}
	}
	return createWebSocket(client, opts)
}

if (IS_BROWSER) {
	module.exports = buildBuilderBrowser
} else {
	module.exports = buildBuilder
}
