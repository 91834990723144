// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

// A bit simpler than readable streams.
// Implement an async ._write(chunk, encoding, cb), and it'll handle all
// the drain event emission and buffering.

'use strict'

/* <replacement> */

var pna = require('process-nextick-args')
/* </replacement> */

module.exports = Writable

/* <replacement> */
function WriteReq(chunk, encoding, cb) {
	this.chunk = chunk
	this.encoding = encoding
	this.callback = cb
	this.next = null
}

// It seems a linked list but it is not
// there will be only 2 of these for each stream
function CorkedRequest(state) {
	var _this = this

	this.next = null
	this.entry = null
	this.finish = function () {
		onCorkedFinish(_this, state)
	}
}
/* </replacement> */

/* <replacement> */
// var asyncWrite = !process.browser && ['v0.10', 'v0.9.'].indexOf(process.version.slice(0, 5)) > -1 ? setImmediate : pna.nextTick;
var asyncWrite = pna.nextTick
/* </replacement> */

/* <replacement> */
var Duplex
/* </replacement> */

Writable.WritableState = WritableState

/* <replacement> */
var inherits = require('inherits')
/* </replacement> */

/* <replacement> */
var internalUtil = {
	deprecate: require('util-deprecate')
}
/* </replacement> */

/* <replacement> */
var Stream = require('./internal/streams/stream')
/* </replacement> */

/* <replacement> */

var Buffer = require('safe-buffer').Buffer
var OurUint8Array = global.Uint8Array || function () { }
function _uint8ArrayToBuffer(chunk) {
	return Buffer.from(chunk)
}
function _isUint8Array(obj) {
	return Buffer.isBuffer(obj) || obj instanceof OurUint8Array
}

/* </replacement> */

var destroyImpl = require('./internal/streams/destroy')

inherits(Writable, Stream)

function nop() { }

function WritableState(options, stream) {
	Duplex = Duplex || require('./_stream_duplex')

	options = options || {}

	// Duplex streams are both readable and writable, but share
	// the same options object.
	// However, some cases require setting options to different
	// values for the readable and the writable sides of the duplex stream.
	// These options can be provided separately as readableXXX and writableXXX.
	var isDuplex = stream instanceof Duplex

	// object stream flag to indicate whether or not this stream
	// contains buffers or objects.
	this.objectMode = !!options.objectMode

	if (isDuplex) this.objectMode = this.objectMode || !!options.writableObjectMode

	// the point at which write() starts returning false
	// Note: 0 is a valid value, means that we always return false if
	// the entire buffer is not flushed immediately on write()
	var hwm = options.highWaterMark
	var writableHwm = options.writableHighWaterMark
	var defaultHwm = this.objectMode ? 16 : 16 * 1024

	if (hwm || hwm === 0) this.highWaterMark = hwm; else if (isDuplex && (writableHwm || writableHwm === 0)) this.highWaterMark = writableHwm; else this.highWaterMark = defaultHwm

	// cast to ints.
	this.highWaterMark = Math.floor(this.highWaterMark)

	// if _final has been called
	this.finalCalled = false

	// drain event flag.
	this.needDrain = false
	// at the start of calling end()
	this.ending = false
	// when end() has been called, and returned
	this.ended = false
	// when 'finish' is emitted
	this.finished = false

	// has it been destroyed
	this.destroyed = false

	// should we decode strings into buffers before passing to _write?
	// this is here so that some node-core streams can optimize string
	// handling at a lower level.
	var noDecode = options.decodeStrings === false
	this.decodeStrings = !noDecode

	// Crypto is kind of old and crusty.  Historically, its default string
	// encoding is 'binary' so we have to make this configurable.
	// Everything else in the universe uses 'utf8', though.
	this.defaultEncoding = options.defaultEncoding || 'utf8'

	// not an actual buffer we keep track of, but a measurement
	// of how much we're waiting to get pushed to some underlying
	// socket or file.
	this.length = 0

	// a flag to see when we're in the middle of a write.
	this.writing = false

	// when true all writes will be buffered until .uncork() call
	this.corked = 0

	// a flag to be able to tell if the onwrite cb is called immediately,
	// or on a later tick.  We set this to true at first, because any
	// actions that shouldn't happen until "later" should generally also
	// not happen before the first write call.
	this.sync = true

	// a flag to know if we're processing previously buffered items, which
	// may call the _write() callback in the same tick, so that we don't
	// end up in an overlapped onwrite situation.
	this.bufferProcessing = false

	// the callback that's passed to _write(chunk,cb)
	this.onwrite = function (er) {
		onwrite(stream, er)
	}

	// the callback that the user supplies to write(chunk,encoding,cb)
	this.writecb = null

	// the amount that is being written when _write is called.
	this.writelen = 0

	this.bufferedRequest = null
	this.lastBufferedRequest = null

	// number of pending user-supplied write callbacks
	// this must be 0 before 'finish' can be emitted
	this.pendingcb = 0

	// emit prefinish if the only thing we're waiting for is _write cbs
	// This is relevant for synchronous Transform streams
	this.prefinished = false

	// True if the error was already emitted and should not be thrown again
	this.errorEmitted = false

	// count buffered requests
	this.bufferedRequestCount = 0

	// allocate the first CorkedRequest, there is always
	// one allocated and free to use, and we maintain at most two
	this.corkedRequestsFree = new CorkedRequest(this)
}

WritableState.prototype.getBuffer = function getBuffer() {
	var current = this.bufferedRequest
	var out = []
	while (current) {
		out.push(current)
		current = current.next
	}
	return out
};

(function () {
	try {
		Object.defineProperty(WritableState.prototype, 'buffer', {
			get: internalUtil.deprecate(function () {
				return this.getBuffer()
			}, '_writableState.buffer is deprecated. Use _writableState.getBuffer ' + 'instead.', 'DEP0003')
		})
	} catch (_) { }
})()

// Test _writableState for inheritance to account for Duplex streams,
// whose prototype chain only points to Readable.
var realHasInstance
if (typeof Symbol === 'function' && Symbol.hasInstance && typeof Function.prototype[Symbol.hasInstance] === 'function') {
	realHasInstance = Function.prototype[Symbol.hasInstance]
	Object.defineProperty(Writable, Symbol.hasInstance, {
		value: function (object) {
			if (realHasInstance.call(this, object)) return true
			if (this !== Writable) return false

			return object && object._writableState instanceof WritableState
		}
	})
} else {
	realHasInstance = function (object) {
		return object instanceof this
	}
}

function Writable(options) {
	Duplex = Duplex || require('./_stream_duplex')

	// Writable ctor is applied to Duplexes, too.
	// `realHasInstance` is necessary because using plain `instanceof`
	// would return false, as no `_writableState` property is attached.

	// Trying to use the custom `instanceof` for Writable here will also break the
	// Node.js LazyTransform implementation, which has a non-trivial getter for
	// `_writableState` that would lead to infinite recursion.
	if (!realHasInstance.call(Writable, this) && !(this instanceof Duplex)) {
		return new Writable(options)
	}

	this._writableState = new WritableState(options, this)

	// legacy.
	this.writable = true

	if (options) {
		if (typeof options.write === 'function') this._write = options.write

		if (typeof options.writev === 'function') this._writev = options.writev

		if (typeof options.destroy === 'function') this._destroy = options.destroy

		if (typeof options.final === 'function') this._final = options.final
	}

	Stream.call(this)
}

// Otherwise people can pipe Writable streams, which is just wrong.
Writable.prototype.pipe = function () {
	this.emit('error', new Error('Cannot pipe, not readable'))
}

function writeAfterEnd(stream, cb) {
	var er = new Error('write after end')
	// TODO: defer error events consistently everywhere, not just the cb
	stream.emit('error', er)
	pna.nextTick(cb, er)
}

// Checks that a user-supplied chunk is valid, especially for the particular
// mode the stream is in. Currently this means that `null` is never accepted
// and undefined/non-string values are only allowed in object mode.
function validChunk(stream, state, chunk, cb) {
	var valid = true
	var er = false

	if (chunk === null) {
		er = new TypeError('May not write null values to stream')
	} else if (typeof chunk !== 'string' && chunk !== undefined && !state.objectMode) {
		er = new TypeError('Invalid non-string/buffer chunk')
	}
	if (er) {
		stream.emit('error', er)
		pna.nextTick(cb, er)
		valid = false
	}
	return valid
}

Writable.prototype.write = function (chunk, encoding, cb) {
	var state = this._writableState
	var ret = false
	var isBuf = !state.objectMode && _isUint8Array(chunk)

	if (isBuf && !Buffer.isBuffer(chunk)) {
		chunk = _uint8ArrayToBuffer(chunk)
	}

	if (typeof encoding === 'function') {
		cb = encoding
		encoding = null
	}

	if (isBuf) encoding = 'buffer'; else if (!encoding) encoding = state.defaultEncoding

	if (typeof cb !== 'function') cb = nop

	if (state.ended) writeAfterEnd(this, cb); else if (isBuf || validChunk(this, state, chunk, cb)) {
		state.pendingcb++
		ret = writeOrBuffer(this, state, isBuf, chunk, encoding, cb)
	}

	return ret
}

Writable.prototype.cork = function () {
	var state = this._writableState

	state.corked++
}

Writable.prototype.uncork = function () {
	var state = this._writableState

	if (state.corked) {
		state.corked--

		if (!state.writing && !state.corked && !state.finished && !state.bufferProcessing && state.bufferedRequest) clearBuffer(this, state)
	}
}

Writable.prototype.setDefaultEncoding = function setDefaultEncoding(encoding) {
	// node::ParseEncoding() requires lower case.
	if (typeof encoding === 'string') encoding = encoding.toLowerCase()
	if (!(['hex', 'utf8', 'utf-8', 'ascii', 'binary', 'base64', 'ucs2', 'ucs-2', 'utf16le', 'utf-16le', 'raw'].indexOf((encoding + '').toLowerCase()) > -1)) throw new TypeError('Unknown encoding: ' + encoding)
	this._writableState.defaultEncoding = encoding
	return this
}

function decodeChunk(state, chunk, encoding) {
	if (!state.objectMode && state.decodeStrings !== false && typeof chunk === 'string') {
		chunk = Buffer.from(chunk, encoding)
	}
	return chunk
}

Object.defineProperty(Writable.prototype, 'writableHighWaterMark', {
	// making it explicit this property is not enumerable
	// because otherwise some prototype manipulation in
	// userland will fail
	enumerable: false,
	get: function () {
		return this._writableState.highWaterMark
	}
})

// if we're already writing something, then just put this
// in the queue, and wait our turn.  Otherwise, call _write
// If we return false, then we need a drain event, so set that flag.
function writeOrBuffer(stream, state, isBuf, chunk, encoding, cb) {
	if (!isBuf) {
		var newChunk = decodeChunk(state, chunk, encoding)
		if (chunk !== newChunk) {
			isBuf = true
			encoding = 'buffer'
			chunk = newChunk
		}
	}
	var len = state.objectMode ? 1 : chunk.length

	state.length += len

	var ret = state.length < state.highWaterMark
	// we must ensure that previous needDrain will not be reset to false.
	if (!ret) state.needDrain = true

	if (state.writing || state.corked) {
		var last = state.lastBufferedRequest
		state.lastBufferedRequest = {
			chunk: chunk,
			encoding: encoding,
			isBuf: isBuf,
			callback: cb,
			next: null
		}
		if (last) {
			last.next = state.lastBufferedRequest
		} else {
			state.bufferedRequest = state.lastBufferedRequest
		}
		state.bufferedRequestCount += 1
	} else {
		doWrite(stream, state, false, len, chunk, encoding, cb)
	}

	return ret
}

function doWrite(stream, state, writev, len, chunk, encoding, cb) {
	state.writelen = len
	state.writecb = cb
	state.writing = true
	state.sync = true
	if (writev) stream._writev(chunk, state.onwrite); else stream._write(chunk, encoding, state.onwrite)
	state.sync = false
}

function onwriteError(stream, state, sync, er, cb) {
	--state.pendingcb

	if (sync) {
		// defer the callback if we are being called synchronously
		// to avoid piling up things on the stack
		pna.nextTick(cb, er)
		// this can emit finish, and it will always happen
		// after error
		pna.nextTick(finishMaybe, stream, state)
		stream._writableState.errorEmitted = true
		stream.emit('error', er)
	} else {
		// the caller expect this to happen before if
		// it is async
		cb(er)
		stream._writableState.errorEmitted = true
		stream.emit('error', er)
		// this can emit finish, but finish must
		// always follow error
		finishMaybe(stream, state)
	}
}

function onwriteStateUpdate(state) {
	state.writing = false
	state.writecb = null
	state.length -= state.writelen
	state.writelen = 0
}

function onwrite(stream, er) {
	var state = stream._writableState
	var sync = state.sync
	var cb = state.writecb

	onwriteStateUpdate(state)

	if (er) onwriteError(stream, state, sync, er, cb); else {
		// Check if we're actually ready to finish, but don't emit yet
		var finished = needFinish(state)

		if (!finished && !state.corked && !state.bufferProcessing && state.bufferedRequest) {
			clearBuffer(stream, state)
		}

		if (sync) {
			/* <replacement> */
			asyncWrite(afterWrite, stream, state, finished, cb)
			/* </replacement> */
		} else {
			afterWrite(stream, state, finished, cb)
		}
	}
}

function afterWrite(stream, state, finished, cb) {
	if (!finished) onwriteDrain(stream, state)
	state.pendingcb--
	cb()
	finishMaybe(stream, state)
}

// Must force callback to be called on nextTick, so that we don't
// emit 'drain' before the write() consumer gets the 'false' return
// value, and has a chance to attach a 'drain' listener.
function onwriteDrain(stream, state) {
	if (state.length === 0 && state.needDrain) {
		state.needDrain = false
		stream.emit('drain')
	}
}

// if there's something in the buffer waiting, then process it
function clearBuffer(stream, state) {
	state.bufferProcessing = true
	var entry = state.bufferedRequest

	if (stream._writev && entry && entry.next) {
		// Fast case, write everything using _writev()
		var l = state.bufferedRequestCount
		var buffer = new Array(l)
		var holder = state.corkedRequestsFree
		holder.entry = entry

		var count = 0
		var allBuffers = true
		while (entry) {
			buffer[count] = entry
			if (!entry.isBuf) allBuffers = false
			entry = entry.next
			count += 1
		}
		buffer.allBuffers = allBuffers

		doWrite(stream, state, true, state.length, buffer, '', holder.finish)

		// doWrite is almost always async, defer these to save a bit of time
		// as the hot path ends with doWrite
		state.pendingcb++
		state.lastBufferedRequest = null
		if (holder.next) {
			state.corkedRequestsFree = holder.next
			holder.next = null
		} else {
			state.corkedRequestsFree = new CorkedRequest(state)
		}
		state.bufferedRequestCount = 0
	} else {
		// Slow case, write chunks one-by-one
		while (entry) {
			var chunk = entry.chunk
			var encoding = entry.encoding
			var cb = entry.callback
			var len = state.objectMode ? 1 : chunk.length

			doWrite(stream, state, false, len, chunk, encoding, cb)
			entry = entry.next
			state.bufferedRequestCount--
			// if we didn't call the onwrite immediately, then
			// it means that we need to wait until it does.
			// also, that means that the chunk and cb are currently
			// being processed, so move the buffer counter past them.
			if (state.writing) {
				break
			}
		}

		if (entry === null) state.lastBufferedRequest = null
	}

	state.bufferedRequest = entry
	state.bufferProcessing = false
}

Writable.prototype._write = function (chunk, encoding, cb) {
	cb(new Error('_write() is not implemented'))
}

Writable.prototype._writev = null

Writable.prototype.end = function (chunk, encoding, cb) {
	var state = this._writableState

	if (typeof chunk === 'function') {
		cb = chunk
		chunk = null
		encoding = null
	} else if (typeof encoding === 'function') {
		cb = encoding
		encoding = null
	}

	if (chunk !== null && chunk !== undefined) this.write(chunk, encoding)

	// .end() fully uncorks
	if (state.corked) {
		state.corked = 1
		this.uncork()
	}

	// ignore unnecessary end() calls.
	if (!state.ending && !state.finished) endWritable(this, state, cb)
}

function needFinish(state) {
	return state.ending && state.length === 0 && state.bufferedRequest === null && !state.finished && !state.writing
}
function callFinal(stream, state) {
	stream._final(function (err) {
		state.pendingcb--
		if (err) {
			stream.emit('error', err)
		}
		state.prefinished = true
		stream.emit('prefinish')
		finishMaybe(stream, state)
	})
}
function prefinish(stream, state) {
	if (!state.prefinished && !state.finalCalled) {
		if (typeof stream._final === 'function') {
			state.pendingcb++
			state.finalCalled = true
			pna.nextTick(callFinal, stream, state)
		} else {
			state.prefinished = true
			stream.emit('prefinish')
		}
	}
}

function finishMaybe(stream, state) {
	var need = needFinish(state)
	if (need) {
		prefinish(stream, state)
		if (state.pendingcb === 0) {
			state.finished = true
			stream.emit('finish')
		}
	}
	return need
}

function endWritable(stream, state, cb) {
	state.ending = true
	finishMaybe(stream, state)
	if (cb) {
		if (state.finished) pna.nextTick(cb); else stream.once('finish', cb)
	}
	state.ended = true
	stream.writable = false
}

function onCorkedFinish(corkReq, state, err) {
	var entry = corkReq.entry
	corkReq.entry = null
	while (entry) {
		var cb = entry.callback
		state.pendingcb--
		cb(err)
		entry = entry.next
	}
	if (state.corkedRequestsFree) {
		state.corkedRequestsFree.next = corkReq
	} else {
		state.corkedRequestsFree = corkReq
	}
}

Object.defineProperty(Writable.prototype, 'destroyed', {
	get: function () {
		if (this._writableState === undefined) {
			return false
		}
		return this._writableState.destroyed
	},
	set: function (value) {
		// we ignore the value if the stream
		// has not been initialized yet
		if (!this._writableState) {
			return
		}

		// backward compatibility, the user is explicitly
		// managing destroyed
		this._writableState.destroyed = value
	}
})

Writable.prototype.destroy = destroyImpl.destroy
Writable.prototype._undestroy = destroyImpl.undestroy
Writable.prototype._destroy = function (err, cb) {
	this.end()
	cb(err)
}
